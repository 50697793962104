import React, { Component, Fragment } from "react";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  Box
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { green } from "@material-ui/core/colors";
import { Formik } from "formik";
import * as Yup from "yup";

import ValidatorService from "../../services/ValidatorService";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginTop: 0
  },
  container: {
    padding: theme.spacing(2)
  },
  center: {
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  leftBtn: {
    marginRight: theme.spacing(2)
  },
  btnWrapper: {
    paddingTop: theme.spacing()
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const SellerSchema = () =>
  Yup.object().shape({
    firstname: Yup.string()
      .max(50, "Saisie incorrecte")
      .required("Ce champ est requis"),
    lastname: Yup.string()
      .max(50, "Saisie incorrecte")
      .required("Ce champ est requis"),
    email: Yup.string()
      .max(100, "Saisie incorrecte")
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
    job: Yup.string().max(50, "Saisie incorrecte"),
    phone: Yup.mixed().test("check-phone", "Format invalide", value =>
      value ? ValidatorService.validatePhone(value) : true
    ),
    canSofinco: Yup.boolean(),
    canFranfinance: Yup.boolean(),
    canApplyDiscount: Yup.boolean(),
    canUpdateProductPrice: Yup.boolean()
  });

const EditSellerSchema = () =>
  Yup.object().shape({
    firstname: Yup.string()
      .max(50, "Saisie incorrecte")
      .required("Ce champ est requis"),
    lastname: Yup.string()
      .max(50, "Saisie incorrecte")
      .required("Ce champ est requis"),
    email: Yup.string()
      .max(100, "Saisie incorrecte")
      .email("L'e-mail n'est pas valide")
      .required("Ce champ est requis"),
    job: Yup.string().max(50, "Saisie incorrecte"),
    phone: Yup.mixed().test("check-phone", "Format invalide", value =>
      value ? ValidatorService.validatePhone(value) : true
    ),
    canSofinco: Yup.boolean(),
    canFranfinance: Yup.boolean(),
    canApplyDiscount: Yup.boolean(),
    canUpdateProductPrice: Yup.boolean(),
    internalId: Yup.string()
      .max(50, "Saisie incorrecte")
      .nullable()
  });

@inject("sessionStore")
@observer
class AddSellerForm extends Component {
  render() {
    const {
      classes,
      handleSubmit,
      isSubmitting,
      handleCancel,
      seller,
      submitBtnText = "Enregister",
      sessionStore
    } = this.props;

    const companyHasFinancing =
      sessionStore.user.company.features.includes("OPTION_SOFINCO") ||
      sessionStore.user.company.features.includes("OPTION_FRANFINANCE");

    return (
      <Formik
        enableReinitialize
        initialValues={seller}
        validationSchema={seller.id ? EditSellerSchema : SellerSchema}
        onSubmit={values => {
          handleSubmit({ ...values });
        }}
      >
        {({ values, touched, errors, isValid, handleChange, handleBlur }) => {
          console.log(" ERRORS ", errors);
          return (
            <form>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <div className={classes.paper}>
                    <TextField
                      name="firstname"
                      placeholder="John"
                      label="Prénom"
                      margin="normal"
                      variant="outlined"
                      required
                      fullWidth
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.firstname && touched.firstname}
                      helperText={errors.firstname && touched.firstname && errors.firstname}
                    />
                    <TextField
                      name="lastname"
                      placeholder="Doe"
                      label="Nom"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      required
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.lastname && touched.lastname}
                      helperText={errors.lastname && touched.lastname && errors.lastname}
                    />
                    <TextField
                      name="email"
                      placeholder="john.doe@example.org"
                      label="Adresse Email"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      required
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      type="email"
                    />
                    <TextField
                      name="phone"
                      placeholder="0600112233"
                      label="Téléphone"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      value={values.phone}
                      onChange={handleChange}
                      type="phone"
                      onBlur={handleBlur}
                      error={errors.phone && touched.phone}
                      helperText={errors.phone && touched.phone && errors.phone}
                    />
                    <TextField
                      name="job"
                      placeholder="Utilisateur"
                      label="Poste"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      value={values.job}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.job && touched.job}
                      helperText={errors.job && touched.job && errors.job}
                    />
                    <TextField
                      name="internalId"
                      placeholder="XXX"
                      label="Matricule"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      value={values.internalId || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.internalId && touched.internalId}
                      helperText={errors.internalId && touched.internalId && errors.internalId}
                    />
                    <Typography variant="subtitle1" className={classes.center} style={{ marginTop: 8 }}>
                      Produits (prix et remise)
                    </Typography>
                    <Box style={{ display: "flex", flexDirection: "column", width: "100%", gap: "2px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!values.canUpdateProductPrice}
                            onChange={handleChange}
                            name="canUpdateProductPrice"
                            color="primary"
                          />
                        }
                        label="Peut modifier le prix d'un produit catalogue"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!values.canApplyDiscount}
                            onChange={handleChange}
                            name="canApplyDiscount"
                            color="primary"
                          />
                        }
                        label="Peut appliquer des remises sur les prestations"
                      />
                    </Box>
                    {companyHasFinancing && (
                      <Fragment>
                        <Typography variant="subtitle1" className={classes.center} style={{ marginTop: 8 }}>
                          Financement
                        </Typography>
                        <Box style={{ display: "flex", flexDirection: "column", width: "100%", gap: "2px" }}>
                          {sessionStore.user.company.features.includes("OPTION_SOFINCO") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!values.canSofinco}
                                  onChange={handleChange}
                                  name="canSofinco"
                                  color="primary"
                                />
                              }
                              label="Peut créer des simulations avec Sofinco"
                            />
                          )}
                          {sessionStore.user.company.features.includes("OPTION_FRANFINANCE") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!values.canFranfinance}
                                  onChange={handleChange}
                                  name="canFranfinance"
                                  color="primary"
                                />
                              }
                              label="Peut créer des simulations avec Franfinance"
                            />
                          )}
                        </Box>
                      </Fragment>
                    )}
                    <Grid justify="flex-end" container className={classes.btnWrapper}>
                      <Button variant="outlined" color="primary" onClick={handleCancel} className={classes.leftBtn}>
                        Annuler
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit(values)}
                        disabled={isSubmitting || !isValid}
                      >
                        {submitBtnText}
                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Button>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default withStyles(styles)(AddSellerForm);
