import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import AdminService from "../../../services/AdminService";

const useStyles = makeStyles(theme => ({
  link: { display: "block" },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  }
}));

export default function CompanyMailConfiguration({ company, onPatch }) {
  const classes = useStyles();
  const [mailConfiguration, setMailConfiguration] = React.useState(company.mailConfiguration);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    AdminService.patchCompany({
      ...company,
      mailConfiguration
    })
      .then(updatedCompany => {
        onPatch(updatedCompany);
        enqueueSnackbar("L'entreprise a été mise à jour", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'entreprise", {
          variant: "error"
        });
      });
  };

  const handleReset = () => {
    setMailConfiguration(company.mailConfiguration);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Configuration des emails
            </Typography>
            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Box>
                <Typography variant="body2" gutterBottom>
                  Nom de l&apos;expéditeur
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  name="senderName"
                  value={mailConfiguration.senderName}
                  onChange={e => setMailConfiguration({ ...mailConfiguration, senderName: e.target.value })}
                  fullWidth
                />
              </Box>
            </Box>
          </CardContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button variant="text" onClick={handleReset}>
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Enregistrer
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
