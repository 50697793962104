import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import CompaniesSelect from "./CompaniesSelect";

export default function ExportCompanyContent({ onSubmit, allowEmpty }) {
  const [companyId, setCompanyId] = React.useState("");

  const handleCompanyChange = _company => {
    setCompanyId(_company.id);
  };

  return (
    <React.Fragment>
      <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", gap: "12px" }}>
        <CompaniesSelect onChange={handleCompanyChange} selectedValue={companyId} />
        <Button variant="outlined" color="primary" onClick={() => onSubmit(companyId)}>
          Valider
        </Button>
      </Box>
      {allowEmpty && <Typography variant="caption">Si vide, export tous les utilisateurs</Typography>}
    </React.Fragment>
  );
}
